<template>
<b-card>
    <!-- form -->
    <b-form>
        <p class="text-center mt-2" v-show="showActivation">
            <b-button @click="resendCode" id="verify">
                <feather-icon icon="LoaderIcon" v-show="isLoading" />
                <feather-icon icon="ChevronDownIcon" /> Send Activation Code
            </b-button>
        </p>
        <b-row v-show="!showActivation">
            <!-- old password -->
            <b-col md="12">
                <b-form-group label="Activation Code" label-for="account-old-password">
                    <b-input-group class="input-group-merge">
                        <b-form-input id="account-old-password" v-model="passwordValueOld" name="old-password" :type="passwordFieldTypeOld" placeholder="Verification Code" />
                        <b-input-group-append is-text>
                            <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!--/ old password -->
        </b-row>
        <b-row>
            <!-- new password -->
            <b-col md="6" v-show="!showActivation">
                <b-form-group label-for="account-new-password" label="New Password">
                    <b-input-group class="input-group-merge">
                        <b-form-input id="account-new-password" v-model="newPasswordValue" :type="passwordFieldTypeNew" name="new-password" placeholder="New Password" />
                        <b-input-group-append is-text>
                            <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!--/ new password -->

            <!-- retype password -->
            <b-col md="6" v-show="!showActivation">
                <b-form-group label-for="account-retype-new-password" label="Retype New Password">
                    <b-input-group class="input-group-merge">
                        <b-form-input id="account-retype-new-password" v-model="RetypePassword" :type="passwordFieldTypeRetype" name="retype-password" placeholder="New Password" />
                        <b-input-group-append is-text>
                            <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!--/ retype password -->

            <!-- buttons -->
            <b-col cols="12" v-show="!showActivation">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" @click="updatePassword">
                    Save changes
                </b-button>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mt-1">
                    Reset
                </b-button>
            </b-col>
            <!--/ buttons -->
        </b-row>
    </b-form>
</b-card>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
} from 'bootstrap-vue'
import {
    heightFade
} from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $ from 'jquery'
import store from '@/store'
export default {
    components: {
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BInputGroup,
        BInputGroupAppend,
    },
    directives: {
        heightFade,
        Ripple,
    },
    data() {
        return {
            showActivation: true,
            isLoading: false,
            passwordValueOld: '',
            newPasswordValue: '',
            RetypePassword: '',
            passwordFieldTypeOld: 'password',
            passwordFieldTypeNew: 'password',
            passwordFieldTypeRetype: 'password',
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            errorMessage: "",
        }
    },
    computed: {
        passwordToggleIconOld() {
            return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordToggleIconNew() {
            return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordToggleIconRetype() {
            return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods: {
        togglePasswordOld() {
            this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
        },
        togglePasswordNew() {
            this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
        },
        togglePasswordRetype() {
            this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        resendCode() {
            let vm = this
            vm.isLoading = true
            if (vm.$cookies.get("clientData").mobile) {
                vm.isLoading = false
                $('#verify').html(' Please Wait ...');
                $.ajax({
                    url: store.state.rootUrl + 'account/v1/forget_password',
                    type: "POST",
                    async: true,
                    crossDomain: true,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + store.state.accessToken
                    },
                    contentType: 'application/json',
                    processData: false,
                    dataType: 'json',
                    data: JSON.stringify({
                        user_name: vm.$cookies.get("clientData").mobile,
                        country_code: vm.$cookies.get("country_code"),
                    }),
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        $('#verify').html(' Send Activation Code');
                        if (response.data.code != 200) {
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message

                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.showActivation = false

                        }

                    },
                    error: function (jQxhr, status, error) {
                        vm.isLoading = false
                        vm.dismissCountDown = vm.dismissSecs
                        $('#verify').html(' Send Activation Code');
                        var errorMessage = jQxhr.responseJSON.errors[0];
                        var statustext = jQxhr.responseJSON.message;
                        vm.showActivation = true;
                        $('#login').text('login')
                        if (!statustext) {
                            vm.errorMessage = "An error occurred. Try again later.";
                        } else {
                            vm.errorMessage = statustext + " " + errorMessage;
                        }
                    }
                });
            }
        },
        updatePassword() {
            let vm = this
             $('#updatePWD').html('Please wait...');
            $.post({
                url: store.state.rootUrl + 'account/v1/verify_account',
                type: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + store.state.accessToken
                },
                contentType: 'application/json',
                processData: false,
                dataType: 'json',
                data: JSON.stringify({
                    user_name: vm.$cookies.get("clientData").mobile,
                    country_code: vm.$cookies.get("country_code"),
                    verification_code: vm.passwordValueOld,
                    new_pass: btoa(vm.newPasswordValue)
                }),
                success: function (response, status, jQxhr) {
                   $('#updatePWD').html('Save changes');
                    if (response.data.code != 200) {
                        vm.dismissCountDown = vm.dismissSecs
                        vm.showDismissibleAlert = true
                        vm.errorMessage = response.data.message
                        if (response.data.code == 121) {
                            vm.showResendActivation = true
                        }

                    } else {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                        vm.$router.push({
                            name: 'account-setting'
                        })
                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                    vm.dismissCountDown = vm.dismissSecs
                    $('#updatePWD').html('Save changes');
                    var errorMessage = jQxhr.responseJSON.errors[0];
                    var statustext = jQxhr.responseJSON.message;
                    vm.showDismissibleAlert = true;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        }
    },
}
</script>
