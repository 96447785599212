<template>
<b-card>
    <!-- form -->
    <b-form>
        <b-row>
            <!-- bio -->
            <b-col cols="12">
                <b-form-group label="Bio" label-for="bio-area">
                    <b-form-textarea id="bio-area" v-model="bio" rows="4" placeholder="Your bio data here..." />
                </b-form-group>
            </b-col>
            <!--/ bio -->

            <!-- birth date -->
            <b-col md="6">
                <b-form-group label-for="example-datepicker" label="Birth date">
                    <flat-pickr v-model="dob" class="form-control" name="date" placeholder="Birth date" />
                </b-form-group>
            </b-col>
            <!--/ birth date -->

            <!-- Country -->
            <b-col md="6">
                <b-form-group label-for="countryList" label="Country">
                    <v-select id="countryList" v-model="country" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="countryOption" />
                </b-form-group>
            </b-col>
            <!--/ Country -->

            <!-- website -->
            <!--/ website -->

            <!-- phone -->
            <!-- phone -->

            <b-col cols="12">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1">
                    Save changes
                </b-button>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" class="mt-1" variant="outline-secondary" @click.prevent="resetForm">
                    Reset
                </b-button>
            </b-col>
        </b-row>
    </b-form>
</b-card>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $ from 'jquery'
import store from '@/store'
export default {
    components: {
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BFormTextarea,
        vSelect,
        flatPickr,
        Cleave,
    },
    directives: {
        Ripple,
    },
    props: {
        informationData: {
            type: Object,
            default: () => {},

        },
    },
    data() {
        return {
            countryOption: ['USA', 'India', 'Canada'],
            clevePhone: {
                phone: true,
                phoneRegionCode: 'KE',
               
            },
             dob: "",
                bio: "",
                phone: "",
                country: ""
        }
    },
    methods: {
        resetForm() {

        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
