<template>
  <b-card>

    <!-- media -->
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>
      <b-row>
    
      <b-col sm="6">
          <b-form-group
            label="Phone"
            label-for="account-username"
          >
            <b-form-input
              v-model="user.msisdn"
              placeholder="Phone"
              name="phone"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Username"
            label-for="account-username"
          >
            <b-form-input
              v-model="user.full_names"
              placeholder="Username"
              name="username"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="First Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="user.first_name"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Middle Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="user.middle_name"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Surname"
            label-for="account-name"
          >
            <b-form-input
              v-model="user.sur_name"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="user.email_address"
              name="email"
              placeholder="Email"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Company"
            label-for="account-company"
          >
            <b-form-input
              v-model="user.client_name"
              name="company"
              placeholder="Company name"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            id="updateAccount"
             @click.prevent="resetForm"
          >
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $ from 'jquery'
import store from '@/store'
import {
    heightFade
} from '@core/directives/animations'
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,heightFade
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading:false,
      profileFile: null,
      avatar: "",
      user: {},
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      errorMessage: "",
    }
  },
  mounted(){
    let vm = this
    vm.fetchRecord()
  },
  methods: {
    countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
    fetchRecord() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/users',
                type: "get",
                async: true,
                crossDomain: true,
                data: {
                    limit: 10,
                    user_id: vm.$cookies.get("clientData").userId
                },
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    vm.user = response.data.data.data[0]
                },
                error: function (jQxhr, status, error) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error fetching users list',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                }
            });

        },
    resetForm() {
      let vm  = this
       $('#updateAccount').html('Please wait...');
            $.post({
                url: store.state.rootUrl + 'account/v1/edit_user',
                type: "POST",
               headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    userMapId: vm.user.user_mapId,
                    roleId: vm.user.role_id,
                    emailAddress: vm.user.email_address,
                    username: vm.user.full_names,
                    firstName: vm.user.first_name,
                    lastName: vm.user.middle_name,
                    surName: vm.user.sur_name,
                    status: vm.user.account_status,
                }),
                success: function (response, status, jQxhr) {
                   $('#updateAccount').html('Save changes');
                    if (response.data.code != 200) {
                        vm.dismissCountDown = vm.dismissSecs
                        vm.showDismissibleAlert = true
                        vm.errorMessage = response.data.message
                        if (response.data.code == 121) {
                            vm.showResendActivation = true
                        }

                    } else {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                        vm.$router.push({
                            name: 'account-setting'
                        })
                    }

                },
                error: function (jQxhr, status, error) {
                   $('#updateAccount').html('Save changes');
                    vm.isLoading = false
                    vm.dismissCountDown = vm.dismissSecs
                   
                    vm.showDismissibleAlert = true;
                    vm.errorMessage = jQxhr.responseJSON.statusDescription 
                  }
                
            });
    },
    //userId
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
